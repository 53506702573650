import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

/* ***Layouts**** */
const FullLayout = lazy(() => import('../layouts/full-layout/FullLayout'));
const BlankLayout = lazy(() => import('../layouts/blank-layout/BlankLayout'));
/* ***End Layouts**** */

const Error = lazy(() => import('../views/authentication/Error'));
const Confirm = lazy(() => import('../views/authentication/Confirm'));
const Login = lazy(() => import('../views/authentication/Login'));
const Register = lazy(() => import('../views/authentication/Register'));
const ResetPassword = lazy(() => import('../views/authentication/ResetPassword'));
const NewPassword = lazy(() => import('../views/authentication/NewPassword'));

/* ****Pages***** */
const Dashboard1 = lazy(() => import('../views/dashboards/Dashboard1'));
const Detail = lazy(() => import('../views/shipments/detail'));
const CreateShipment = lazy(() => import('../views/quote/createShipment'));
const Quote = lazy(() => import('../views/quote/quote'));
const AddressForm = lazy(() => import('../views/quote/address'));
const Address = lazy(() => import('../views/address/address'));
const Packages = lazy(() => import('../views/packages/packages'));
const Confirmation = lazy(() => import('../views/quote/confirmation'));
const Payments = lazy(() => import('../views/payments/payments'));
const Shipments = lazy(() => import('../views/shipments/shipments'));
const Store = lazy(() => import('../views/store/store'));
const Orders = lazy(() => import('../views/store/orders'));
const Profile = lazy(() => import('../views/profile'));
const DetailBalance = lazy(() => import('../views/profile/detailBalance'));
const Users = lazy(() => import('../views/users'));
const Help = lazy(() => import('../views/help'));
// const Dashboard2 = lazy(() => import('../views/dashboards/Dashboard2'));
// const Dashboard3 = lazy(() => import('../views/dashboards/Dashboard3'));

/* ****Apps***** */
// const Chats = lazy(() => import('../views/apps/chats/Chats'));
// const Notes = lazy(() => import('../views/apps/notes/Notes'));
// const Email = lazy(() => import('../views/apps/email/Email'));
// const Shop = lazy(() => import('../views/apps/shop/Shop'));
// const QuillEditor = lazy(() => import('../views/quill-editor/QuillEditor'));
// const Treeview = lazy(() => import('../views/treeview/Treeview'));
// const Pricing = lazy(() => import('../views/pricing/Pricing'));
// const CustomTimeline = lazy(() => import('../views/timeline/CustomTimeline'));
// const CustomTypography = lazy(() => import('../views/typography/CustomTypography'));
// const Calendar = lazy(() => import('../views/apps/calendar/ACalendar'));
// const CustomerEdit = lazy(() => import('../views/apps/customers/CustomerEdit'));
// const CustomerLists = lazy(() => import('../views/apps/customers/CustomerLists'));
/* ****Tables***** */
// const BasicTable = lazy(() => import('../views/tables/BasicTable'));
// const PaginationTable = lazy(() => import('../views/tables/PaginationTable'));
// const EnhancedTable = lazy(() => import('../views/tables/EnhancedTable'));
// const CollapsibleTable = lazy(() => import('../views/tables/CollapsibleTable'));
// const FixedHeaderTable = lazy(() => import('../views/tables/FixedHeaderTable'));

// form elements
// const ExAutoComplete = lazy(() => import('../views/form-elements/ExAutoComplete'));
// const ExButton = lazy(() => import('../views/form-elements/ExButton'));
// const ExCheckbox = lazy(() => import('../views/form-elements/ExCheckbox'));
// const ExDateTime = lazy(() => import('../views/form-elements/ExDateTime'));
// const ExRadio = lazy(() => import('../views/form-elements/ExRadio'));
// const ExSlider = lazy(() => import('../views/form-elements/ExSlider'));
// const ExSwitch = lazy(() => import('../views/form-elements/ExSwitch'));
// const FormWizard = lazy(() => import('../views/form-layouts/FormWizard'));
// form layouts
// const FormLayouts = lazy(() => import('../views/form-layouts/FormLayouts'));
// const FormCustom = lazy(() => import('../views/form-layouts/FormCustom'));

// widgets
// const WidgetFeed = lazy(() => import('../views/widgets/widget-feed/WidgetFeed'));
// const WidgetApps = lazy(() => import('../views/widgets/widget-apps/WidgetApps'));

// userprofile
// const UserProfile = lazy(() => import('../views/user-profile/UserProfile'));
// const ShopDetail = lazy(() => import('../views/apps/shop-detail/ShopDetail'));

// chart
// const LineChart = lazy(() => import('../views/charts/LineChart'));
// const GredientChart = lazy(() => import('../views/charts/GredientChart'));
// const DoughnutChart = lazy(() => import('../views/charts/DoughnutChart'));
// const AreaChart = lazy(() => import('../views/charts/AreaChart'));
// const ColumnChart = lazy(() => import('../views/charts/ColumnChart'));
// const CandlestickChart = lazy(() => import('../views/charts/CandlestickChart'));
// const RadialbarChart = lazy(() => import('../views/charts/RadialbarChart'));

// icons
// const ReactIcons = lazy(() => import('../views/icons/ReactIcons'));

// Alert
// const ExAlert = lazy(() => import('../views/alert/ExAlert'));

/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to={localStorage.session !== undefined ? "/dashboard" : "/auth/login"} /> },
      { path: '/dashboard', exact: true, element: <Dashboard1 /> },
      { path: '/create', exact: true, element: <CreateShipment /> },
      { path: '/quote', exact: true, element: <Quote /> },
      { path: '/quote/address', exact: true, element: <AddressForm /> },
      { path: '/address', exact: true, element: <Address /> },
      { path: '/packages', exact: true, element: <Packages /> },
      { path: '/shipments', exact: true, element: <Shipments /> },
      { path: '/quote/:shipmentNumber', exact: true, element: <Confirmation /> },
      { path: '/shipments/:shipmentStatus', exact: true, element: <Shipments /> },
      { path: '/shipment/detail/:shipmentNumber', exact: true, element: <Detail /> },
      { path: '/payments', exact: true, element: <Payments /> },
      { path: '/profile', exact: true, element: <Profile /> },
      { path: '/balance', exact: true, element: <DetailBalance /> },
      { path: '/store', exact: true, element: <Store /> },
      { path: '/orders', exact: true, element: <Orders /> },
      { path: '/users', exact: true, element: <Users /> },
      { path: '/help', exact: true, element: <Help /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: 'confirm', element: <Confirm /> },
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'new-password', element: <NewPassword /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
